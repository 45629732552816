<template>
    <div id="header" class="header">
        <iframe name="VeeamProduct"
            src='https://partnerportal.sophos.com/en-us/microsite/overview.aspx?partner_referral_id=0013Z00001mP5zE&partner_name=PWA'
            scrolling="yes" title="sophos" style="overflow: hidden;" width="100%" height="100%"
            frameborder="0"></iframe>
    </div>
</template>

<script >
export default {
    name: 'sophos',
    components: {
    },
    data() {
        return {
        };
    },
    created() {
        document.getElementById("globalNavbar").className = "navbar navbar-expand-lg navbar-custom fixed-top top-nav-collapse";
    },
    methods: {
    },
};
</script>
<style scoped>
html {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
}

html::-webkit-scrollbar {
    display: none;
}

.header {
    background-color: #fff;
    width: 100vw;
    height: 100vh;
    overflow: hidden;


}
</style>
